@font-face {
  font-family: 'Pragmatica';
  src: url('pragmatica.woff2') format('woff2'),
      url('pragmatica.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pragmatica';
  src: url('pragmatica-medium.woff2') format('woff2'),
      url('pragmatica-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

